import { semelleAssemblyFrontViewOffset, semelleAssemblyHeight, semelleAssemblyLeftViewOffset, semelleAssemblyScene, placageAssemblyScene } from "@lumiscaphe/gpg-webrender-tools"
import { getFrontDepth } from "./frame.service";

// build and transform frame scenes

// TODO: in the case of the Dimensions sur-mesure, the frontView is comming weird, i must verify the frontView calculation
export const buildAndTransformFrameScenes = (configuration, selectedLayout) => {
  const frame = configuration.frame;
  const transDime = transformFrameParts(configuration.dimensions)
  // swap array item if 2 items
  const height = frame.heights.length === 1 ? frame.heights[0] : [frame.heights[1], frame.heights[0]]
  const frameData = {
    database: "63a558ca-171c-4240-8a59-7c2e050b538c",
    assemblyType: getAssemblyType(frame.layout) || "PP",
    bedding: frame.bedding,
    height: height,
    parts: transDime,
    hammered: ["B", "E"].includes(frame.kind) ? true : false,
    frontView: getFrontDepth(frame, selectedLayout.size),
    overall: {
      width: frame.width,
      depth: frame.depth
    },
    leftView: (frame.width - selectedLayout.size.width) / 2,
    recette: {
      width: selectedLayout.size.width,
      depth: selectedLayout.size.depth
    }
  }

  console.log("frameData", frameData);

  const transformedData = transformFrameScenes(frameData, configuration);

  if (selectedLayout?.reference === "A" && frame.kind === "C" && frame.reference === "AS140240C05") {
    transformedData.frameScenes = [];
  }

  return transformedData;
}

export const buildAndTransformVeneerScenes = (configuration, transPoints, frame, selectedLayout) => {
  const veneer = configuration.veneer;
  if (!veneer?.layout) return [];

  const transformedParts = transformVeneerParts(veneer);
  const thickness = veneer.dimensions?.front?.thickness;
  const veneerData = {
    database: "1309d3af-deb0-4b1c-9a1f-80dcfb42785c",
    assemblyType: veneer.assemblyType || "PP",
    thickness: thickness,
    parts: transformedParts,
    overall: getVeneerOverall(veneer, frame, selectedLayout),
  }

  console.log("veneerData", veneerData);

  return transformVeneerScenes(veneerData, configuration, transPoints);
}

const transformFrameScenes = (frameData, configuration) => {
  const semelleTranslationX = semelleAssemblyLeftViewOffset(frameData);
  const semelleTranslationY = -semelleAssemblyHeight(frameData) - 0.001;
  const semelleTranslationZ = -semelleAssemblyFrontViewOffset(frameData);

  const graniteMain = configuration.monument.graniteMain;
  const frameScenes = semelleAssemblyScene(frameData).map(p => {
    return ({
      ...p,
      materials: p.materials ? p.materials.map((m) => ({ ...m, filename: "Granites\\" + graniteMain })) : [],
      translation: {
        x: (p.translation?.x ?? 0) + semelleTranslationX,
        y: (p.translation?.y ?? 0) + semelleTranslationY,
        z: (p.translation?.z ?? 0) + semelleTranslationZ
      },
      visible: true
    })
  });

  return {
    frameScenes,
    transPoints: {
      semelleTranslationX,
      semelleTranslationY,
      semelleTranslationZ
    }
  };
}

const transformVeneerScenes = (veneerData, configuration, transPoints) => {
  const { semelleTranslationX, semelleTranslationY, semelleTranslationZ } = transPoints;

  const placageTranslationX = semelleTranslationX || 0;
  const placageTranslationY = semelleTranslationY != 0 ? (semelleTranslationY - 0.001) : 0;
  const placageTranslationZ = semelleTranslationZ || 0;

  const graniteMain = configuration.monument.graniteMain;

  const veneerScenes = placageAssemblyScene(veneerData).map(p => {
    return ({
      ...p,
      materials: p.materials ? p.materials.map((m) => ({ ...m, filename: "Granites\\" + graniteMain })) : [],
      translation: {
        x: (p.translation?.x ?? 0) + placageTranslationX,
        y: (p.translation?.y ?? 0) + placageTranslationY,
        z: (p.translation?.z ?? 0) + placageTranslationZ
      },
      visible: true
    })
  });

  return veneerScenes;
}

const transformVeneerParts = (veneer) => {
  const dimensions = veneer.dimensions;

  const final = {
    front: {
      width: dimensions.front.length,
      height: dimensions.front.length != 0 ? [dimensions.front.left, dimensions.front.right] : 0,
    },
    back: {
      width: dimensions.back.length,
      height: dimensions.back.length != 0 ? [dimensions.back.right, dimensions.back.left] : 0,
    },
    left: {
      width: dimensions.left.length,
      height: dimensions.left.length != 0 ? [dimensions.left.back, dimensions.left.front] : 0,
    },
    right: {
      width: dimensions.right.length,
      height: dimensions.right.length != 0 ? [dimensions.right.front, dimensions.right.back] : 0,
    }
  }

  return final;
}

const transformFrameParts = (dimensions) => {
  const leftPart = dimensions.left;
  const newPart = {
    width: leftPart.depth,
    depth: leftPart.width,
  }

  return {
    ...dimensions,
    left: newPart,
    right: newPart,
  }
}

const getVeneerOverall = (veneer, frame, selectedLayout) => {
  if (veneer.width && veneer.length) {
    return {
      width: veneer.width,
      depth: veneer.length,
    }
  }

  // get the overall dimensions from veneer details string ex ("Dimensions hors tout : 238 cm  x 148 cm")
  if (veneer.details) {
    const details = veneer.details;
    const dimensions = details.split("Dimensions hors tout : ")[1]?.replace("  ", " ")?.split("cm x ");
    return {
      width: Number(dimensions[1].split("cm")[0]?.replace(" ", "")),
      depth: Number(dimensions[0]?.replace(" ", "")),
    }
  }

  if (frame.width && frame.depth) {
    return {
      width: frame.width,
      depth: frame.depth,
    }
  }
  
  if (selectedLayout.size.width && selectedLayout.size.depth) {
    return {
      width: selectedLayout.size.width,
      depth: selectedLayout.size.depth,
    }
  }

  return {
    width: 0,
    depth: 0,
  }
}

const getAssemblyType = (layout, frontLayout = "parpaing") => {
  const layoutMap = {
    "parpaing": "P",
    "closoir": "C",
  }

  return `${layoutMap[frontLayout]}${layoutMap[layout]}`;
}
